import {
    STROLLER_BOOLEAN_YES_WITH_ADAPTERS,
    STROLLER_BOOLEAN_YES_WITH_COMBO,
    STROLLER_BOOLEAN_YES_WITHOUT_ADAPTERS,
} from 'mk/autogenerated/translations/other.136941a4f5731206f19b0b019fc02362'
import { BooleanFeature as BooleanFeatureComponent } from 'mk2/apps/strollers/components/features/BooleanFeature';
import { BooleanListFeature as BooleanListFeatureComponent } from 'mk2/apps/strollers/components/features/BooleanListFeature';
import { TwoDimsListFeature as TwoDimsListFeatureComponent } from 'mk2/apps/strollers/components/features/TwoDimsListFeature';
import { Feature } from 'mk2/apps/strollers/features/basic';
import { Features, TwoDimsVariant } from 'mk2/schemas';

export type BooleanPair = [boolean, string];
export type BooleanPairs = BooleanPair[];

interface BooleanPairsSetup {
    [K: string]: BooleanPair;
}

class LiberalBooleanFeature extends Feature {
    public Component = BooleanFeatureComponent;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const value = features[this.featureName];
        return (value && value.startsWith('yes'));
    }

    public rawValue(features: Features): any {
        if (!features) {
            return null;
        }
        const value = features[this.featureName];
        return (value && value.startsWith('yes'));
    }
}

export abstract class LiberalBooleanListFeature extends Feature {
    public Component = BooleanListFeatureComponent;
    public abstract BOOLEAN_PAIRS: BooleanPairsSetup;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const arrValue = features[this.featureName];
        if (!arrValue) {
            return false;
        }
        return (arrValue && arrValue.some((value) => value.startsWith('yes')));
    }

    public booleanPairs(features: Features): BooleanPairs {
        if (!features) {
            return [];
        }
        const arrValue = features[this.featureName];
        if (!arrValue) {
            return [];
        }
        return arrValue.map((value) => {
            const pair = this.BOOLEAN_PAIRS[value];
            return [ pair[0], pair[1] ? `(${pair[1]})` : pair[1] ];
        });
    }

    public rawValue(features: Features): any {
        if (!features) {
            return null;
        }
        const arrValue = features[this.featureName];
        return (arrValue && arrValue.some((value) => value.startsWith('yes')));
    }
}

export class SuppliedChangingBagFeature extends LiberalBooleanFeature {
    public Component = BooleanListFeatureComponent;
    public BOOLEAN_PAIRS: BooleanPairsSetup = {
        no: [false, null],
        yes: [true, null],
        yes_with_combo: [true, STROLLER_BOOLEAN_YES_WITH_COMBO],
    };

    public booleanPairs(features: Features): BooleanPairs {
        if (!features) {
            return [];
        }
        const value = features[this.featureName];
        if (!value) {
            return [];
        }
        return [this.BOOLEAN_PAIRS[value]];
    }
}

export class CarSeatCanBeAttached extends LiberalBooleanFeature {
    public BOOLEAN_PAIRS: BooleanPairsSetup = {
        no: [false, null],
        yes_with_adapters: [true, STROLLER_BOOLEAN_YES_WITH_ADAPTERS],
        yes_without_adapters: [true, STROLLER_BOOLEAN_YES_WITHOUT_ADAPTERS],
    };
}

export class CarrycotCanBeAttached extends LiberalBooleanFeature {
    public BOOLEAN_PAIRS: BooleanPairsSetup = {
        no: [false, null],
        yes_with_adapters: [true, STROLLER_BOOLEAN_YES_WITH_ADAPTERS],
        yes_without_adapters: [true, STROLLER_BOOLEAN_YES_WITHOUT_ADAPTERS],
    };
}

export class CarSeatHasToBeBought extends LiberalBooleanFeature {
    // no
    // yes
    // yes_if_not_threecombo
}

export class VariantBooleanListFeature extends Feature {
    public Component = BooleanListFeatureComponent;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const arrValue = features[this.featureName];
        return (arrValue && arrValue.some((value) => value.startsWith('y')));
    }

    public booleanPairs(features: Features): BooleanPairs {
        if (!features) {
            return [];
        }
        const arrValue = features[this.featureName];
        return arrValue.map((value) => {
            return [
                value[0] === 'y',
                value.slice(1),
            ];
        });
    }

    public rawValue(features: Features): any {
        if (!features) {
            return null;
        }
        const arrValue = features[this.featureName];
        return (arrValue && arrValue.some((value) => value.startsWith('y')));
    }
}

export class VariantBooleanListJsonFeature extends Feature {
    public Component = BooleanListFeatureComponent;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const arrValue = features[this.featureName];
        return (arrValue && arrValue.some((value) => value[0]));
    }

    public booleanPairs(features: Features): BooleanPairs {
        if (!features) {
            return [];
        }
        return features[this.featureName];
    }

    public rawValue(features: Features): any {
        if (!features) {
            return null;
        }
        const arrValue = features[this.featureName];
        return (arrValue && arrValue.some((value) => value[0]));
    }
}

export class VariantListWidthDepthFeature extends Feature {
    public Component = TwoDimsListFeatureComponent;

    public getDims(features: Features): TwoDimsVariant[] {
        if (!features) {
            return [];
        }
        return features[this.featureName];
    }
}
