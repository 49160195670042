import {
    NO,
    STROLLER_BACK_SUPPORT_POSITIONING_BUCKLE,
    STROLLER_BACK_SUPPORT_POSITIONING_BUTTON,
    STROLLER_BACK_SUPPORT_POSITIONING_FRONT_RECLINE,
    STROLLER_BACK_SUPPORT_POSITIONING_REAR_RECLINE,
    STROLLER_BACK_SUPPORT_POSITIONING_SIDE_HANDLES,
    STROLLER_BACK_SUPPORT_POSITIONING_SIDE_RECLINE,
    STROLLER_BACK_SUPPORT_POSITIONING_STRAP,
    STROLLER_BACK_SUPPORT_POSITIONING_ZIP,
    STROLLER_BUMPER_BAR_TYPE_BAR,
    STROLLER_BUMPER_BAR_TYPE_T_SHAPED,
    STROLLER_BUMPER_BAR_TYPE_TRAY,
    STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM,
    STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR,
    STROLLER_CARD_RECOMMENDED_CHOICE_TOP,
    STROLLER_CENTRAL_BRAKE_SYSTEM_HAND_OPERATED,
    STROLLER_CENTRAL_BRAKE_SYSTEM_ONE_STEP_CRADLE_BRAKE,
    STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_LIFT,
    STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_PUSH,
    STROLLER_CLEANING_THE_STROLLER_FABRICS_MANUALLY,
    STROLLER_CLEANING_THE_STROLLER_FABRICS_WACHINE_MACHINE,
    STROLLER_FEATURE_BACK_SUPPORT_ALMOST_FULL_LIE_DOWN,
    STROLLER_FEATURE_BACK_SUPPORT_FULL_LIE_DOWN, STROLLER_FEATURE_BACK_SUPPORT_HALF_LIE_DOWN,
    STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_AVERAGE,
    STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT,
    STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL,
    STROLLER_FOLDING_SYSTEM_3D,
    STROLLER_FOLDING_SYSTEM_FLAT,
    STROLLER_FOLDING_SYSTEM_TELESCOPIC,
    STROLLER_FOLDING_SYSTEM_UMBRELLA,
    STROLLER_HANDLE_ADJUSTMENT_FIXED,
    STROLLER_HANDLE_ADJUSTMENT_PIVOTING,
    STROLLER_HANDLE_ADJUSTMENT_TELESCOPIC,
    STROLLER_HARNESS_TYPE_2B,
    STROLLER_HARNESS_TYPE_3B,
    STROLLER_HARNESS_TYPE_5B,
    STROLLER_HARNESS_TYPE_6B,
    STROLLER_MATERIAL_ALUMINIUM,
    STROLLER_MATERIAL_CARBON,
    STROLLER_MATERIAL_CORK,
    STROLLER_MATERIAL_DURAL,
    STROLLER_MATERIAL_FABRIC,
    STROLLER_MATERIAL_FOAM,
    STROLLER_MATERIAL_HARD_RUBBER,
    STROLLER_MATERIAL_IRON,
    STROLLER_MATERIAL_LEATHER,
    STROLLER_MATERIAL_LEATHERETTE,
    STROLLER_MATERIAL_MAGNESIUM,
    STROLLER_MATERIAL_METAL,
    STROLLER_MATERIAL_NYLON,
    STROLLER_MATERIAL_OTHER,
    STROLLER_MATERIAL_PLASTIC,
    STROLLER_MATERIAL_RECYCLED_PLASTIC,
    STROLLER_MATERIAL_RUBBER,
    STROLLER_MATERIAL_STEEL,
    STROLLER_MATERIAL_WOOD,
    STROLLER_NUMBER_OF_KIDS_MORE,
    STROLLER_NUMBER_OF_KIDS_ONE,
    STROLLER_OR_CONJUNCTION,
    STROLLER_ROTATING_FIXATION_WHEELS_FIXED,
    STROLLER_ROTATING_FIXATION_WHEELS_ROTATING,
    STROLLER_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE,
    STROLLER_SUSPENSION_ELASTOMERS,
    STROLLER_SUSPENSION_FRAME,
    STROLLER_SUSPENSION_MECHANICAL,
    STROLLER_SUSPENSION_MECHANICAL_ELASTOMERS,
    STROLLER_SUSPENSION_MECHANICAL_FRAME,
    STROLLER_SUSPENSION_MECHANICAL_ROCKER,
    STROLLER_SUSPENSION_NONE,
    STROLLER_SUSPENSION_PNEUMATIC,
    STROLLER_SUSPENSION_PNEUMATIC_FRAME,
    STROLLER_SUSPENSION_PNEUMATIC_MECHANICAL,
    STROLLER_SUSPENSION_PNEUMATIC_SPRING,
    STROLLER_SUSPENSION_ROCKER,
    STROLLER_SUSPENSION_SPRING,
    STROLLER_SUSPENSION_SPRING_ELASTOMERS,
    STROLLER_SUSPENSION_SPRING_MECHANICAL,
    STROLLER_SUSPENSION_SPRING_MECHANICAL_FRAME,
    STROLLER_SUSPENSION_SPRING_MECHANICAL_PNEUMATIC,
    STROLLER_SUSPENSION_STRAPS,
    STROLLER_TYPE_2IN1,
    STROLLER_TYPE_2KOMBI,
    STROLLER_TYPE_2KOMBI_DEEP_SPORTY,
    STROLLER_TYPE_2KOMBI_DEEP_TRAVEL_SYSTEM,
    STROLLER_TYPE_2KOMBI_SPORTY_TRAVEL_SYSTEM,
    STROLLER_TYPE_3KOMBI,
    STROLLER_TYPE_CHECKED_BAGGAGE,
    STROLLER_TYPE_CITY_TERRAIN,
    STROLLER_TYPE_COUNTRYSIDE_TERRAIN,
    STROLLER_TYPE_DEEP,
    STROLLER_TYPE_GOLF,
    STROLLER_TYPE_HARD_TERRAIN,
    STROLLER_TYPE_LONG_NAME_2KOMBI,
    STROLLER_TYPE_LONG_NAME_3KOMBI,
    STROLLER_TYPE_LONG_NAME_CHECKED_BAGGAGE,
    STROLLER_TYPE_LONG_NAME_CITY_TERRAIN,
    STROLLER_TYPE_LONG_NAME_COUNTRYSIDE_TERRAIN,
    STROLLER_TYPE_LONG_NAME_DEEP,
    STROLLER_TYPE_LONG_NAME_GOLF,
    STROLLER_TYPE_LONG_NAME_HARD_TERRAIN,
    STROLLER_TYPE_LONG_NAME_RETRO,
    STROLLER_TYPE_LONG_NAME_SIBLINGS,
    STROLLER_TYPE_LONG_NAME_SPORT_RUNNING,
    STROLLER_TYPE_LONG_NAME_SPORTY,
    STROLLER_TYPE_RETRO,
    STROLLER_TYPE_SIBLINGS,
    STROLLER_TYPE_SIBLINGS_DOUBLE,
    STROLLER_TYPE_SIBLINGS_TANDEM,
    STROLLER_TYPE_SIBLINGS_TRIPLE,
    STROLLER_TYPE_SPORT_RUNNING,
    STROLLER_TYPE_SPORTY,
    STROLLER_TYPE_TRAVEL_SYSTEM,
    STROLLER_WHEEL_TYPE_WEB_AIR_WHEELS,
    STROLLER_WHEEL_TYPE_WEB_AIRLESS_WHEELS,
    STROLLER_WHEEL_TYPE_WEB_EBONITE_WHEELS,
    STROLLER_WHEEL_TYPE_WEB_FOAM_WHEELS,
    STROLLER_WHEEL_TYPE_WEB_PLASTIC_WHEELS,
    STROLLER_WHEELS_ALL,
    STROLLER_WHEELS_FRONT,
    STROLLER_WHEELS_REAR,
    YES,
} from 'mk/autogenerated/translations/options.1c5342190ad26335d947e2b8c262d528'
import { Feature, FeatureOptions } from 'mk2/apps/strollers/features/basic';
import { Features } from 'mk2/apps/strollers/schemas';
import { niceNumber } from 'mk2/apps/strollers/utils';

export interface Options {
    [K: string]: string;
}

abstract class OptionFeature extends Feature {
    public abstract OPTIONS: Options;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const value = features[this.featureName];
        if (!value) {
            return false;
        }
        return !!this.OPTIONS[value];
    }

    public niceValue(features: Features): string {
        if (!features) {
            return '';
        }
        const value = features[this.featureName];
        if (!value) {
            return '';
        }
        return this.OPTIONS[value];
    }
}

export abstract class ListFeature extends Feature {
    public abstract OPTIONS: Options;

    public hasWorthyValue(features: Features): boolean {
        if (!features) {
            return false;
        }
        const arrValue = features[this.featureName];
        if (!arrValue) {
            return false;
        }
        return arrValue.length > 0;
    }

    public orderBeforeTrans(untranslatedArr: string[]): string[] {
        // default: NO OP
        return untranslatedArr;
    }

    public orderAfterTrans(translatedArr: string[]): string[] {
        // default: alphanumeric sort of translated values
        return [...translatedArr].sort();
    }

    public decodeItem(item: string | number): string {
        return this.OPTIONS[item];
    }

    public format(itemsArr: string[] | number[]): string {
        return itemsArr.join(', ');
    }

    public niceValue(features: Features, options: string[] = null): string {
        if (!features) {
            return '';
        }

        const arrValue = (!options || options.length === 0) ? features[this.featureName] : features[this.featureName].filter(value => options.includes(value));

        if (!arrValue) {
            return '';
        }

        const untranslated = this.orderBeforeTrans(arrValue);
        const translated = untranslated.map((item) => this.decodeItem(item));
        const sorted = this.orderAfterTrans(translated);
        return this.format(sorted);
    }
}

abstract class OrListFeature extends ListFeature {
    public orderAfterTrans(translatedArr: string[]): string[] {
        // default: NO OP
        return translatedArr;
    }

    public format(itemsArr: string[] | number[]): string {
        return itemsArr.join(STROLLER_OR_CONJUNCTION);
    }
}

export class YesNoFeature extends ListFeature {
    public OPTIONS = {
        'yes': YES,
        'no': NO,
    };
}

export class ColorFeature extends ListFeature {
    public OPTIONS = null; // colors are loaded dynamically from db
}

export class ConfigurationFeature extends ListFeature {
    public OPTIONS = null; // list options loaded dynamically from db
}

export class WheelTypeFeature extends ListFeature {
    public OPTIONS = {
        'air_wheels': STROLLER_WHEEL_TYPE_WEB_AIR_WHEELS,
        'airless_wheels': STROLLER_WHEEL_TYPE_WEB_AIRLESS_WHEELS,
        'ebonite_wheels': STROLLER_WHEEL_TYPE_WEB_EBONITE_WHEELS,
        'foam_wheels': STROLLER_WHEEL_TYPE_WEB_FOAM_WHEELS,
        'plastic_wheels': STROLLER_WHEEL_TYPE_WEB_PLASTIC_WHEELS,
        'other': STROLLER_MATERIAL_OTHER,
    };
}

export class BackSupportCanBeInLyingPositionFeature extends OptionFeature {
    public OPTIONS = {
        'full_lie_down': STROLLER_FEATURE_BACK_SUPPORT_FULL_LIE_DOWN,
        'almost_full_lie_down': STROLLER_FEATURE_BACK_SUPPORT_ALMOST_FULL_LIE_DOWN,
        'half_lie_down': STROLLER_FEATURE_BACK_SUPPORT_HALF_LIE_DOWN,
        'no': NO,
    };
}

export class BackSupportPositioningTypeFeature extends ListFeature {
    public OPTIONS = {
        'buckle': STROLLER_BACK_SUPPORT_POSITIONING_BUCKLE,
        'button': STROLLER_BACK_SUPPORT_POSITIONING_BUTTON,
        'front recline': STROLLER_BACK_SUPPORT_POSITIONING_FRONT_RECLINE,
        'rear recline': STROLLER_BACK_SUPPORT_POSITIONING_REAR_RECLINE,
        'side handles': STROLLER_BACK_SUPPORT_POSITIONING_SIDE_HANDLES,
        'side recline': STROLLER_BACK_SUPPORT_POSITIONING_SIDE_RECLINE,
        'strap': STROLLER_BACK_SUPPORT_POSITIONING_STRAP,
        'zip': STROLLER_BACK_SUPPORT_POSITIONING_ZIP,
    };
}

export class CentralBrakeSystemFeature extends ListFeature {
    public OPTIONS = {
        'hand-operated': STROLLER_CENTRAL_BRAKE_SYSTEM_HAND_OPERATED,
        'one-step cradle brake': STROLLER_CENTRAL_BRAKE_SYSTEM_ONE_STEP_CRADLE_BRAKE,
        'push-lift pedal brake': STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_LIFT,
        'push-push pedal brake': STROLLER_CENTRAL_BRAKE_SYSTEM_PUSH_PUSH,
    };
}

export class FoldingSystemFeature extends ListFeature {
    public OPTIONS = {
        '3D': STROLLER_FOLDING_SYSTEM_3D,
        'flat': STROLLER_FOLDING_SYSTEM_FLAT,
        'telescopic': STROLLER_FOLDING_SYSTEM_TELESCOPIC,
        'umbrella': STROLLER_FOLDING_SYSTEM_UMBRELLA,
    };
}

export class MaterialFeature extends ListFeature {
    public OPTIONS = {
        'aluminium': STROLLER_MATERIAL_ALUMINIUM,
        'carbon': STROLLER_MATERIAL_CARBON,
        'cork': STROLLER_MATERIAL_CORK,
        'dural': STROLLER_MATERIAL_DURAL,
        'fabric': STROLLER_MATERIAL_FABRIC,
        'foam': STROLLER_MATERIAL_FOAM,
        'hard rubber': STROLLER_MATERIAL_HARD_RUBBER,
        'iron': STROLLER_MATERIAL_IRON,
        'leather': STROLLER_MATERIAL_LEATHER,
        'leatherette': STROLLER_MATERIAL_LEATHERETTE,
        'magnesium': STROLLER_MATERIAL_MAGNESIUM,
        'metal': STROLLER_MATERIAL_METAL,
        'nylon': STROLLER_MATERIAL_NYLON,
        'plastic': STROLLER_MATERIAL_PLASTIC,
        'recycled plastic': STROLLER_MATERIAL_RECYCLED_PLASTIC,
        'rubber': STROLLER_MATERIAL_RUBBER,
        'steel': STROLLER_MATERIAL_STEEL,
        'wood': STROLLER_MATERIAL_WOOD,
    };
}

export class BumperBarTypeFeature extends ListFeature {
    public OPTIONS = {
        'bar': STROLLER_BUMPER_BAR_TYPE_BAR,
        'T shaped': STROLLER_BUMPER_BAR_TYPE_T_SHAPED,
        'tray': STROLLER_BUMPER_BAR_TYPE_TRAY,
    };
}

export class SuspendedWheelsFeature extends OptionFeature {
    public OPTIONS = {
        all: STROLLER_WHEELS_ALL,
        front: STROLLER_WHEELS_FRONT,
        rear: STROLLER_WHEELS_REAR,
    };
}

export class CleaningTheStrollerFabricsFeature extends OptionFeature {
    public OPTIONS = {
        washing_machine: STROLLER_CLEANING_THE_STROLLER_FABRICS_WACHINE_MACHINE,
        manually: STROLLER_CLEANING_THE_STROLLER_FABRICS_MANUALLY,
    };
}

export class HarnessTypeFeature extends ListFeature {
    public OPTIONS = {
        '2b': STROLLER_HARNESS_TYPE_2B,
        '3b': STROLLER_HARNESS_TYPE_3B,
        '5b': STROLLER_HARNESS_TYPE_5B,
        '6b': STROLLER_HARNESS_TYPE_6B,
    };
}

export class SuspensionTypeFeature extends OptionFeature {
    public OPTIONS = {
        elastomers: STROLLER_SUSPENSION_ELASTOMERS,
        frame: STROLLER_SUSPENSION_FRAME,
        mechanical: STROLLER_SUSPENSION_MECHANICAL,
        mechanical_elastomers: STROLLER_SUSPENSION_MECHANICAL_ELASTOMERS,
        mechanical_frame: STROLLER_SUSPENSION_MECHANICAL_FRAME,
        mechanical_rocker: STROLLER_SUSPENSION_MECHANICAL_ROCKER,
        pneumatic: STROLLER_SUSPENSION_PNEUMATIC,
        pneumatic_mechanical: STROLLER_SUSPENSION_PNEUMATIC_MECHANICAL,
        pneumatic_spring: STROLLER_SUSPENSION_PNEUMATIC_SPRING,
        pneumatic_frame: STROLLER_SUSPENSION_PNEUMATIC_FRAME,
        spring: STROLLER_SUSPENSION_SPRING,
        spring_elastomers: STROLLER_SUSPENSION_SPRING_ELASTOMERS,
        spring_mechanical: STROLLER_SUSPENSION_SPRING_MECHANICAL,
        spring_mechanical_frame: STROLLER_SUSPENSION_SPRING_MECHANICAL_FRAME,
        spring_mechanical_pneumatic: STROLLER_SUSPENSION_SPRING_MECHANICAL_PNEUMATIC,
        straps: STROLLER_SUSPENSION_STRAPS,
        rocker: STROLLER_SUSPENSION_ROCKER,
        none: STROLLER_SUSPENSION_NONE,
    };
}

export class HandleAdjustmentTypeFeature extends OptionFeature {
    public OPTIONS = {
        pivoting: STROLLER_HANDLE_ADJUSTMENT_PIVOTING,
        telescopic: STROLLER_HANDLE_ADJUSTMENT_TELESCOPIC,
        fixed: STROLLER_HANDLE_ADJUSTMENT_FIXED,
    };
}

export class TypeFeature extends ListFeature {
    public LONG_NAME = {
        '2kombi': STROLLER_TYPE_LONG_NAME_2KOMBI,
        '3kombi': STROLLER_TYPE_LONG_NAME_3KOMBI,
        'golf': STROLLER_TYPE_LONG_NAME_GOLF,
        'deep': STROLLER_TYPE_LONG_NAME_DEEP,
        'sporty': STROLLER_TYPE_LONG_NAME_SPORTY,
        'siblings': STROLLER_TYPE_LONG_NAME_SIBLINGS,
        'hard_terrain': STROLLER_TYPE_LONG_NAME_HARD_TERRAIN,
        'city_terrain': STROLLER_TYPE_LONG_NAME_CITY_TERRAIN,
        'countryside_terrain': STROLLER_TYPE_LONG_NAME_COUNTRYSIDE_TERRAIN,
        'sport_running': STROLLER_TYPE_LONG_NAME_SPORT_RUNNING,
        'checked_baggage': STROLLER_TYPE_LONG_NAME_CHECKED_BAGGAGE,
        'retro': STROLLER_TYPE_LONG_NAME_RETRO,
    };

    public OPTIONS = {
        'deep': STROLLER_TYPE_DEEP,
        'sporty': STROLLER_TYPE_SPORTY,
        '2kombi': STROLLER_TYPE_2KOMBI,
        '3kombi': STROLLER_TYPE_3KOMBI,
        'golf': STROLLER_TYPE_GOLF,
        'siblings': STROLLER_TYPE_SIBLINGS, // not a real Type, has special rendering, removed from backend/db
        'hard_terrain': STROLLER_TYPE_HARD_TERRAIN,
        'city_terrain': STROLLER_TYPE_CITY_TERRAIN,
        'countryside_terrain': STROLLER_TYPE_COUNTRYSIDE_TERRAIN,
        'sport_running': STROLLER_TYPE_SPORT_RUNNING,
        'checked_baggage': STROLLER_TYPE_CHECKED_BAGGAGE,
        'retro': STROLLER_TYPE_RETRO,
    };

    public HIDDEN_TYPES = ['siblings'];

    public ORDER = [
        'deep',
        'sporty',
        '2kombi',
        '3kombi',
        // 2in1
        'golf',
        'siblings',
        'hard_terrain',
        'city_terrain',
        'countryside_terrain',
        'sport_running',
        'checked_baggage',
        'retro',
        // travel_system
    ];

    public HP_ORDER = [
        'sporty',
        'deep',
        '2kombi',
        '3kombi',
        'golf',
        'hard_terrain',
    ];

    public orderBeforeTrans(untranslatedArr: string[]): string[] {
        return this.ORDER.filter((item) => {
            return untranslatedArr.indexOf(item) >= 0;
        });
    }

    public orderAfterTrans(translatedArr: string[]): string[] {
        return translatedArr;
    }
}

export class Type2KombiFeature extends ListFeature {
    public OPTIONS = {
        'deep sporty': STROLLER_TYPE_2KOMBI_DEEP_SPORTY,
        'deep travel system': STROLLER_TYPE_2KOMBI_DEEP_TRAVEL_SYSTEM,
        'sporty travel system': STROLLER_TYPE_2KOMBI_SPORTY_TRAVEL_SYSTEM,
    };
}

export class NumberOfKidsFeature extends ListFeature {
    public OPTIONS = {
        'one': STROLLER_NUMBER_OF_KIDS_ONE,
        'more': STROLLER_NUMBER_OF_KIDS_MORE,
    };
}

export class SiblingsTypeFeature extends ListFeature {
    public OPTIONS = {
        'double': STROLLER_TYPE_SIBLINGS_DOUBLE,
        'tandem': STROLLER_TYPE_SIBLINGS_TANDEM,
        'triple': STROLLER_TYPE_SIBLINGS_TRIPLE,
    };
}

export class RecommendedChoiceFeature extends ListFeature {
    public OPTIONS = {
        'top': STROLLER_CARD_RECOMMENDED_CHOICE_TOP,
        'happy medium': STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM,
        'popular': STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR,
    };
}

export class RecommendedChoiceOfCategoriesFeature extends ListFeature {
    public OPTIONS = {
        ...(new TypeFeature('type')).OPTIONS,
        '2in1': STROLLER_TYPE_2IN1,
        'travel_system': STROLLER_TYPE_TRAVEL_SYSTEM,
    };
}

export class RotatingFixationWheelsFeature extends OrListFeature {
    public OPTIONS = {
        'rotating lockable': STROLLER_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE,
        'rotating': STROLLER_ROTATING_FIXATION_WHEELS_ROTATING,
        'fixed': STROLLER_ROTATING_FIXATION_WHEELS_FIXED,
    };
}

export class StrollerFeatureStrollerForParentByHeightFeature extends OptionFeature {
    public OPTIONS = {
        'height_lt_150': STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT,
        'height_150_to_170': STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_AVERAGE,
        'height_gt_170': STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL,
    };
}
