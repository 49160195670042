import { LikeableEntity } from 'mk2/containers/Like/Like.schemas';
import {
    AST,
    ASTAdditionalDataFromServer,
    Entity,
    PhotoEntity,
    UserEntity, XMLImportItemEntity,
} from 'mk2/schemas';
import { schema } from 'normalizr';

export interface TwoDimsVariant {
    width: number;
    depth: number;
    variant: string;
}

export interface StrollerUserReviewPhotoEntity extends Entity {
    review?: StrollerUserReviewEntity;
    photoFile: PhotoEntity;
    position: number;
}

export enum StrollerUserReviewStatus {
    NOT_APPROVED_YET = 0,
    NOT_VERIFIED_AND_APPROVED = 1,
    VERIFIED_AND_APPROVED = 2,
    NOT_APPROVED_WANT_VERIFY,

}

export interface StrollerUserReviewEntity extends Entity {
    score: number;
    strollerId: number;
    user: UserEntity;
    oneliner: string;
    perex: string;
    detailedReview: boolean;
    approved: boolean;
    verified: boolean;
    deleted: boolean;
    showDetailLink: boolean;
    createdTime: string;
    text: string;
    positives: string[];
    negatives: string[];
    photos: StrollerUserReviewPhotoEntity[];
    status: StrollerUserReviewStatus;
    likeable: LikeableEntity;
}

export interface SiblingSlugs {
    201?: string;
    202?: string;
}

export interface StrollerStrollerPhoto extends Entity {
    photo: StrollerPhoto;
    orderIndex: number;
    originalUrl: string;
}

export interface Features {
    adjustable_handle?: boolean;
    adjustable_suspension?: boolean;
    all_wheels_are_double?: boolean;
    autolock?: boolean;
    back_support_can_be_extended?: boolean;
    back_support_can_be_in_lying_position?: 'full_lie_down' | 'almost_full_lie_down' | 'half_lie_down' | 'no';
    back_support_can_be_positioned?: boolean;
    back_support_length_in_cm?: {min: number, max: number};
    back_support_positioning_type?: string[];
    back_support_positions_l?: number[];
    big_shopping_basket?: boolean;
    bucket_seat?: boolean;
    bucket_second_seat?: boolean;
    bumper_bar?: boolean;
    bumper_bar_can_be_bought_separately?: boolean;
    bumper_bar_can_be_positioned?: boolean;
    bumper_bar_can_be_removed?: boolean;
    bumper_bar_equipped_with_a_removable_cover?: boolean;
    bumper_bar_type?: string[];
    can_be_converted_to_a_double?: boolean;
    can_be_converted_to_a_single?: boolean;
    can_be_pulled_behind?: boolean;
    car_seat_can_be_attached?: 'no' | 'yes_with_adapters' | 'yes_without_adapters';
    car_seat_compatibility_most_brands?: boolean;
    car_seat_compatibility_roemer?: boolean;
    car_seat_has_to_be_bought?: string;
    carrycot_can_be_attached?: 'no' | 'yes_with_adapters' | 'yes_without_adapters';
    carrycot_dims_in_cm?: TwoDimsVariant[];
    carrycot_has_handle?: Array<[boolean, string]>;
    carrycot_has_ventilation?: string[];
    carrycot_usable_in_car?: string[];
    carrycot_with_cradle?: string[];
    carrycot_with_ventilation_window_on_roof?: boolean;
    carrycot_with_panoramic_window_on_side?: boolean;
    central_brake_present?: boolean;
    central_brake_system?: string[];
    checked_baggage?: boolean;
    cleaning_the_stroller_fabrics?: string;
    closable_peek_a_boo_window?: boolean;
    cupholder_possibility?: boolean;
    discontinued?: boolean;
    discontinued_year?: number;
    extendable_hood?: boolean;
    foldable_carrycot?: Array<[boolean, string]>;
    foldable_handrail?: boolean;
    folded_dimensions_in_cm?: {height: number, length: number; width: number};
    folding_system?: string[];
    folds_by_itself?: boolean;
    folds_with_both_seats_attached?: boolean;
    folds_with_seat_outwards?: boolean;
    folds_with_the_seat_attached?: boolean;
    folds_with_the_seat_in_reverse?: boolean;
    follow_the_sun_hood?: boolean;
    front_wheels_diameter_in_cm?: number;
    fully_fledged_second_seat?: boolean;
    handle_height_in_cm?: {min: number, max: number};
    handle_material?: string[];
    harness_padding?: boolean;
    harness_type?: string[];
    has_carry_handle?: boolean;
    height_adjustable_harness?: boolean;
    height_adjustable_hood?: boolean;
    height_adjustable_seat?: boolean;
    higher_positioned_carrycot?: boolean;
    higher_seat_position?: boolean;
    hood_equipped_with_a_pocket?: boolean;
    hood_equipped_with_uv_protection?: boolean;
    hood_equipped_with_ventilation_panel?: boolean;
    hood_features_a_sun_visor?: boolean;
    inclinable_carrycot?: Array<[boolean, string]>;
    led_lighting?: boolean;
    leg_rest_can_be_extended?: boolean;
    leg_rest_can_be_positioned?: boolean;
    leg_rest_positions?: number;
    manual_brake_present?: boolean;
    maximum_load_per_1st_child_in_kg?: number;
    maximum_load_per_2nd_child_in_kg?: number;
    maximum_load_per_child_in_kg?: number;
    memory_button?: boolean;
    model_url?: string;
    motorized?: boolean;
    number_of_double_wheels?: number;
    number_of_wheels_l?: number[];
    one_hand_folding?: boolean;
    one_hand_positioning?: boolean;
    origin_countries?: number[];
    parent_tray_organizer?: boolean;
    peek_a_boo_window_in_the_hood?: boolean;
    peek_a_boo_window_with_silent_closure?: boolean;
    platform_material?: string[];
    possibility_of_terrain_wheels?: boolean;
    type_2in1_seat?: boolean;
    product_is_no_longer_sold_bool?: boolean;
    product_is_no_longer_sold_int?: string;
    rear_wheels_diameter_in_cm?: number;
    recommended_age?: {min: number, max: number};
    removable_hood?: boolean;
    retro?: boolean;
    retroreflector?: boolean;
    roof_present?: boolean;
    back_support_can_be_in_vertical_seat_position?: boolean;
    rotating_seat?: boolean;
    rotating_second_seat?: boolean;
    seat_dims_in_cm_depth?: number;
    seat_dims_in_cm_width?: number;
    seat_fabrics_can_be_taken_off?: boolean;
    seat_surface_total_length_in_cm?: {min: number, max: number};
    seat_unit_stroller_body_equipped_with_ventilation_system?: boolean;
    second_seat_back_support_can_be_positioned?: boolean;
    second_seat_back_support_length_in_cm?: {min: number, max: number};
    second_seat_back_support_positioning_type?: string[];
    second_seat_back_support_positions_l?: number[];
    second_seat_bumper_bar?: boolean;
    second_seat_dims_in_cm_depth?: number;
    second_seat_dims_in_cm_width?: number;
    second_seat_leg_rest?: boolean;
    second_seat_one_hand_positioning?: boolean;
    second_seat_recommended_age?: {min: number, max: number};
    second_seat_roof_present?: boolean;
    second_seat_surface_total_length_in_cm?: {min: number, max: number};
    siblings_type?: 'single' | 'double' | 'tandem' | 'triple';
    shopping_basket?: boolean;
    shopping_basket_capacity_in_kg?: number;
    shopping_basket_closable?: boolean;
    standing_fold?: boolean;
    stroller_body_equipped_with_a_pocket?: boolean;
    supplied_cape?: boolean;
    supplied_changing_bag?: string;
    supplied_footmuff?: boolean;
    supplied_mosquito_net?: boolean;
    supplied_pad?: boolean;
    supplied_transport_bag?: boolean;
    suspended_wheels?: string;
    suspension?: boolean;
    suspension_type?: 'elastomers' | 'frame' | 'mechanical' | 'mechanical_elastomers' | 'mechanical_frame' | 'mechanical_rocker' | 'pneumatic' | 'pneumatic_mechanical' | 'pneumatic_spring' | 'pneumatic_frame' | 'spring' | 'spring_elastomers' | 'spring_mechanical' | 'spring_mechanical_frame' | 'spring_mechanical_pneumatic' | 'straps' | 'rocker' | 'none' ;
    swing_away_bumper_bar?: boolean;
    swinging?: boolean;
    two_car_seats_can_be_attached?: boolean;
    two_wheel_mode?: boolean;
    type?: string[];
    type_of_handle_adjustment?: 'pivoting' | 'telescopic' | 'fixed';
    unfolded_dimensions_in_cm?: {height: number, length: number; width: number};
    video_url?: object[];
    waterproof_hood?: boolean;
    weight_in_kg?: number;
    wheels_can_be_removed?: boolean;
    wheels_3_vs_4?: '3_wheel' | '4_wheel';
    wrist_safety_strap?: boolean;
    wheel_description_sk?: string;
    wheel_description_cz?: string;
    rotating_fixation_wheels?: 'rotating lockable' | 'rotating' | 'fixed';
    stroller_for_parent_by_height?: 'height_lt_150' | 'height_150_to_170' | 'height_gt_170';
    spacious_seat?: boolean;
    weight_is_low?: boolean;
    use_terrain?: 'city' | 'travel' | 'running_sport' |  'village' | 'terrain';
    very_good_folding?: boolean;
    recommended_choice?: 'top' | 'happy medium' | 'popular';
    recommended_choice_of_categories?: string[];
}

type WheelTypeValue = 'air_wheels' | 'airless_wheels' | 'foam_wheels' | 'plastic_wheels' | 'ebonite_wheels' | 'other';

export interface VariantAttributesWithOptions {
    variant_rear_wheel_type?: WheelTypeValue[];
    variant_front_wheel_type?: WheelTypeValue[];
    variant_on_market_from?: number[];
}

export interface ConfiguredFeatures {
    config_adapters?: string;
    config_adapters_carrycot?: string;
    config_base_color?: { label: string; color: string };
    config_board?: string;
    config_bumper_bar?: string;
    config_car_seat?: string;
    config_carrycot?: string;
    config_color?: { label: string; color: string } | { label: string; pattern: string };
    config_construction?: string;
    config_duo_carrycot?: string;
    config_duo_seat?: string;
    config_extension_set?: string;
    config_footcover_color?: { label: string; color: string };
    config_footmuff?: string;
    config_footstool?: string;
    config_handle_color?: { label: string; color: string };
    config_height_adapter?: string;
    config_mosquito_net?: string;
    config_organizer?: string;
    config_raincoat?: string;
    config_roof?: string;
    config_stroller_baby_bag?: string;
    config_stroller_bag?: string;
    config_stroller_foot_bag?: string;
    config_stroller_seat?: string;
    config_sun_visor?: string;
    config_twin_carrycot?: string;
    config_wheel_color?: { label: string; color: string };
    config_young_reduction?: string;
}

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;
export type ConfigurableFeaturesWithOptions = PartialRecord<keyof ConfiguredFeatures, Array<string | { label: string; color: string }>>;

export interface StrollerQuestionEntity extends Entity {
    strollerId: number;
    question: string;
    answer: string;
}

export interface StrollerEntity extends Entity {
    name: string;
    slug: string;
    siblingSlugs: SiblingSlugs;
    prepared: boolean;
    userScore: number; // avg rating of reviews checked by staff
    features: Features;
    configurables: ConfigurableFeaturesWithOptions;
    configurablesOrder: string[];
    variantAttributes: VariantAttributesWithOptions;
    previewPhoto: StrollerPhoto;
    reviewsCount: number;  // count of approved not-deleted reviews, including reviews from aLikeStrollers
    brand: StrollerBrandEntity;
    deletedAt: string;
    priceUsedMin: string; // decimal, aby float v js nedaval 5.23999999 namiesto 5.24
    priceUsedMax: string; // decimal, aby float v js nedaval 5.23999999 namiesto 5.24
    minPricedEshopProduct: string;
    maxPricedEshopProduct: string;
    subVariantFeaturesConfig: Record<string, string[][]>;
}

export interface StrollerPhoto {
    id: number;
    originalWidth: number;
    originalHeight: number;
    url100x100: string;
    url250x250: string;
    url720x720: string;
}

export interface StrollerBrandEntity extends Entity {
    name: string;
    slug: string;
    descriptionAST: AST;
    descriptionASTAdditionalData: ASTAdditionalDataFromServer;
    brandUrl: string;
    logoPhoto: StrollerPhoto;
    strollerPhoto: StrollerPhoto;
}

export interface StrollerVariantPhotoEntity extends Entity {
    photo: StrollerPhoto;
    originalUrl: string;
}

export interface StrollerVariantsPhotosEntity extends Entity {
    variantId: number;
    variantPhoto: StrollerVariantPhotoEntity;
    orderIndex: number;
}

export interface StrollerVariantEntity extends Entity {
    strollerId: number;
    features: ConfiguredFeatures;
    bazaarFeatures: Record<string, string[]>;
    previewPhoto: StrollerPhoto;
    minPricedEshopProduct: string;
    maxPricedEshopProduct: string;
}
export interface StrollerPatternEntity extends Entity {
    code: string;
    colors: string[];
    deletedAt: string;
    originStroller: string;
    patternImage: PhotoEntity;
}

export interface VariantsOptionsEntity {
    [key: number]: VariantOptionsEntity;
}

export interface VariantOptionsEntity {
    [key: string]: VariantOptionEntity;
}

export interface VariantOptionEntity {
    featureName: string;
    options: VariantOptionValuesEntity[];
}

export interface VariantOptionValuesEntity {
    value: string;
    color?: string;
    pattern?: string;
    label: string;
    linkedId: number;
    isAvailable: boolean;
    selected: boolean;
    disabled: boolean;
    optionPhoto: number;
}

export interface UserConfigurationEntity {
    [key: string]: string;
}

export interface StrollerPhotoEditorEntity {
    card?: ConfigurableFeaturesWithOptions;
    photos?: number[];
    subId?: number;
}

export type StrollerPhotoEditorMatrix = StrollerPhotoEditorEntity[][];

export interface StrollerSubVariantEntity {
    'id': number;
    'subFeatures': ConfigurableFeaturesWithOptions;
    'targetFeature': ConfigurableFeaturesWithOptions;
    'photos': number[];
}

export const StrollerSubVariantSchema = new schema.Entity('strollers.strollersubvariant');
export const PhotoSchema = new schema.Entity('strollers.photo'); // keep in mind, there is also another PhotoSchema
export const StrollerSchema = new schema.Entity('strollers.stroller', {
    previewPhoto: PhotoSchema,
});
export const PhotoDetailSchema = new schema.Entity('strollers.photo');
export const StrollerBrandSchema = new schema.Entity('strollers.strollerbrand', {
    logoPhoto: PhotoSchema,
    strollerPhoto: PhotoSchema,
});
export const StrollerPhotoSchema = new schema.Entity('strollers.strollerphoto', {
    // stroller: StrollerSchema, // breaking circular references
    photo: PhotoSchema,
});
export const StrollerUserReviewSchema = new schema.Entity('strollers.strolleruserreview');
export const StrollerUserReviewPhotoSchema = new schema.Entity('strollers.strolleruserreviewphoto', {
    review: StrollerUserReviewSchema,
});
export const StrollerQuestionSchema = new schema.Entity('strollers.strollerquestion', {});

export const StrollerVariantSchema = new schema.Entity('strollers.strollervariant', {
    previewPhoto: PhotoSchema,
});
export const StrollerVariantPhotoSchema = new schema.Entity('strollers.strollervariantphoto', {
    variant: StrollerVariantSchema,
    photo: PhotoSchema,
});
export const StrollerVariantsPhotosSchema = new schema.Entity('strollers.strollervariantsphotos', {
    variantPhoto: StrollerVariantPhotoSchema,
});
export const StrollerPatternSchema = new schema.Entity('strollers.strollerpattern');
